import React, { useState } from 'react';

export const AccordionIndex = ({
  items,
  backGroundColor,
  activeBackGroundColor
}) => {
const [isExpanded, setIsExpanded] = useState({});
const toggleExpand = index => {
    setIsExpanded(prevState => ({[index]: !prevState[index] }));
  };
  return (
    <>
    <ul className='flex flex-col px-1 space-y-2 md:space-y-1 w-full'>
  {items.map((item, index) => (
    <li key={index} className={`${isExpanded[index] ? activeBackGroundColor : backGroundColor} flex flex-col space-y-3 px-4 md:px-7 text-xs`}>
      <p className='flex items-center font-bold pt-3 pb-0 md:pt-4 md:pb-1'>
        <span
          className={`bg-white p-2 rounded-full font-extrabold mr-6 text-xl cursor-pointer text-center ${isExpanded[index] ? backGroundColor : activeBackGroundColor}`}
          onClick={() => toggleExpand(index)}
        >
         {isExpanded[index] ? 
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="white" className='rounded-full h-5 w-5 cursor-pointer'>
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M6 12h12" />
          </svg> 
        : 
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#58C8DF" className=' rounded-full h-5 w-5 cursor-pointer '>
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
      }
        </span>
        <span className={`text-sm ${isExpanded[index] ? "text-black" : "text-white"}`}>{item.title}</span>
      </p>
      <div
        style={{
          maxHeight: isExpanded[index] ? '1000px' : '0',
          transition: 'max-height 0.3s ease-in-out',
          overflow: 'hidden'
        }}
      >
        <p className='pb-5 text-sm text-black'>{item.content}</p>
      </div>
    </li>
  ))}
</ul>
    </>
  )
}