import './swiper.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper/core';
import 'swiper/css/bundle';
import { useState } from 'react';
import MaytronicsLogo from '../svg/MaytronicsLogo';
import StarIcon from '../svg/StarIcon';
import DefaultProfile from '../../images/default-profile.png';
export const SwiperComponent = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);

SwiperCore.use([Navigation]);
  const handleSlideChange = (swiper) => {
    // setActiveIndex(swiper.realIndex);
    setActiveIndex(swiper.activeIndex);
  };
  const slides = data.map((item, index) => {
    const isActive = index === activeIndex;
  
    return (
      <SwiperSlide key={`slide-${index}`} 
      className={`transition-all my-swiper-slide h-full flex flex-col justify-center items-center`} 
      style={{ width: '100%', boxSizing: 'border-box' }}>
      <div className='w-full p-3 md:px-0 lg:px-3 flex gap-3 flex-col justify-between design text-center bg-cover bg-center relative' 
        style={{
          boxSizing: 'border-box',
          borderRadius: '20px',
          boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 1)',
          overflow: `auto`,
          transition: 'height 0.3s',
          position: 'relative',
          backgroundImage: `url(${item.background})`,
          zIndex: 1,
        }}
      >
        <div className={`absolute w-full h-1/2 bottom-0 left-0 half-background`} style={{ background: '#58C8DF' }}></div>
<div className={` flex-grow bg-gray-100 text-black flex flex-col overflow-hidden justify-between pb-5 px-5 md:px-3 lg:px-5 mt-6 mx-4 card-body`}>
  <div className='h-full flex flex-col text-sm md:text-xs lg:text-sm font-display font-black leading-tight overflow-hidden whitespace-no-wrap mb-5 mt-3'>
    <div className='flex justify-center items-center my-3'>
      <StarIcon className="h-5 opacity-100 rating"/>
      <StarIcon className="h-5 opacity-100 rating"/>
      <StarIcon className="h-5 opacity-100 rating"/>
      <StarIcon className="h-5 opacity-100 rating"/> 
      <StarIcon className="h-5 opacity-100 rating"/>
    </div>
    <div className='flex-grow overflow-auto'>
      <p className='h-full font-bold'>{item.text}</p>
    </div>
  </div>
  <div className='w-full text-center'>
    <div className='font-display mx-auto px-3 person-name text-sm truncate text-white footer-text' style={{fontWeight:"900"}}>{item.footer}</div>
  </div>
</div>

      
      <div className='opacity-0 py-2'>,</div>
      <div className='absolute bottom-4 left-1/2 transform -translate-x-1/2 w-28'><MaytronicsLogo fill={"#ffff"}/></div>
  </div>
</SwiperSlide>
    );
  });

  return (
    <Swiper
  centeredSlides={true}
  loop={true}
  onSlideChange={handleSlideChange}
  style={{ width: '100%', height: '100%', overflow: 'visible' }}
  breakpoints={{
    0: {
      slidesPerView: 1,
      spaceBetween: -5
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 7
    }
  }}
  navigation
  speed={1000}
>
      {slides}
    </Swiper>
  );
};
