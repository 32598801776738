import { useState, useEffect } from 'react'
import axios from 'axios'
import * as Realm from 'realm-web'

const REALM_APP_ID = 'mtau-web-app-esnki'
const app = new Realm.App({ id: REALM_APP_ID })

async function loginApiKey(apiKey) {
	// Create an API Key credential
	const credentials = Realm.Credentials.apiKey(apiKey)
	try {
		// Authenticate the user
		const user = await app.logIn(credentials)
		// `App.currentUser` updates to match the logged in user
		if (user.id === app.currentUser.id) return user
		return new Error('user does not match currentUser')
	} catch (err) {
		console.error('Failed to log in', err)
	}
}

export const dealerTypes = [
	{ name: 'Elite Dealer', type: 'elite' },
	{ name: 'Robotic Repairs', type: 'roboticrepairs' },
	{ name: 'Spare Parts', type: 'elite' },
	{ name: 'Mineral Swim', type: 'mineralswim' },
	{ name: 'Water Treatment Repairs', type: 'watertreatmentrepairs' },
	{ name: 'Pool Builder', type: 'elitebuilder' },
]
export function useAddressSearch(searchTerm, locale) {
	const [addresses, setAddresses] = useState([])
	useEffect(() => {
		if (searchTerm.trim() !== '') {
			let isFresh = true
			fetchAddresses(searchTerm, locale)
				.then((addresses) => {
					const features = addresses.features.map(
						({ place_name, geometry }) => ({
							name: place_name,
							coordinates: geometry.coordinates,
						})
					)
					if (isFresh) setAddresses(features)
					return
				})
				.catch((e) => e)
			return () => (isFresh = false)
		}
	}, [searchTerm])
	return addresses
}
const addressCache = {}
const dealerCache = {}
const fetchAddresses = async (value, locale) => {
	let country
	locale === '' || locale === 'en-AU'
		? (country = 'au')
		: locale === 'en-NZ'
		? (country = 'nz')
		: (country = 'au')
	const ENDPOINT = 'mapbox.places'
	const SEARCH_TEXT = encodeURI(value)
	const MAPBOX_TOKEN = process.env.GATSBY_MAPBOX_TOKEN
	const URI = `https://api.mapbox.com/geocoding/v5/${ENDPOINT}/${SEARCH_TEXT}.json?access_token=${MAPBOX_TOKEN}&autocomplete=true&country=${country}&types=postcode%2Caddress&limit=5`
	if (addressCache[value]) {
		return Promise.resolve(addressCache[value])
	}
	try {
		const response = await axios.get(URI)
		if (response.status === 404)
			throw new Error('MapBox found no matches for that address / postcode.')
		return response.data
	} catch (error) {
		// console.log(error)
	}
}
export function useDealerSearch(type, coordinates, setDealer, locale, limit) {
	const [dealers, setDealers] = useState([])
	const [loading, setLoading] = useState(false)
	useEffect(() => {
		if (type && Array.isArray(coordinates) && coordinates.length === 2) {
			let isFresh = true
			const filter = JSON.stringify({ type, status: 'active' })
			setLoading(true)
			fetchDealers(filter, coordinates, 3000, 3, locale, limit)
				.then((data) => {
					if (isFresh) {
						const hasOne = data.length === 1
						const hasTwo = data.length <= 2
						const inCluster =
							/elitebuilder|mineralswim/.test(type) ||
							(hasTwo && data[1].meta.distance <= 6000) ||
							(hasOne && data[0].meta.distance <= 6000)
						const dealers = inCluster ? data : [data[0]]
						setDealers(dealers)
						setDealer(dealers[0])
						setLoading(false)
					}
					return
				})
				.catch((e) => e)
			return () => (isFresh = false)
		}
	}, [coordinates, setDealer, type])
	return { dealers, loading }
}
const fetchDealers = async (
	filter,
	coordinates,
	delay,
	tries,
	locale,
	limit = 2
) => {
	const url = '/.netlify/functions/graphql'
	const variables = {
		query: JSON.parse(filter),
		coordinates,
		maxDistance: 400000,
		limit: limit,
	}
	const query = `query dealersByDistance($query:String,$coordinates:[Float!],$maxDistance:Int,$limit:Int) {dealersByDistance(query:$query,coordinates:$coordinates,maxDistance:$maxDistance,limit:$limit) {affiliateId,name,email,phone,social{minisite},address{city,postalCode},logo,location{coordinates},meta{distance},type}}`
	if (dealerCache[filter && coordinates]) {
		return Promise.resolve(dealerCache[filter && coordinates])
	}
	const onError = async (error) => {
		let triesLeft = tries - 1
		if (!triesLeft) throw error
		return wait(delay).then(() =>
			fetchDealers(filter, coordinates, delay, triesLeft)
		)
	}
	const user = await loginApiKey(process.env.GATSBY_REALM_API)
	try {
		console.log('User: ', user)
		console.log('Request: ', variables)
		// const res = await axios.post(url, { query, variables })
		/**
		 * Find dealers
		 * Query the dealers database for the closest dealers
		 * @param {Object|unknown} query dealer type and active fields
		 * @param {Float32Array} coordinates [lat,lng]
		 * @param {Number} maxDistance from coordinates
		 * @param {Number} limit the amount of docs returned
		 * @return {Promise<Object>} Array of dealers
		 */
		const res = await user.functions.findDealers(
			JSON.parse(filter),
			coordinates,
			400000,
			limit
		)
		console.log('Result: ', res)
		return res.filter((d) => d !== null)
	} catch (error) {
		alert(JSON.stringify(error, undefined, 2))
		await onError(error)
	}
}
const wait = (delay) => new Promise((resolve) => setTimeout(resolve, delay))
