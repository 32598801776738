import React from 'react';
const steps = {
  "step1":[
    {
      "title": "SOOTHES & RELAXES MUSCLES & JOINTS",
      "content": "Mineral Swim™ 100% Dead Sea minerals are rich in magnesium salt, which is known for its anti-inflammatory properties and for maintaining healthy muscles. This could help with arthritis, mobility, leg cramps, and pain. Your muscles will feel soothed and relaxed after every swim."
    },
    {
      "title": "REJUVENATES & SOFTENS SKIN & HAIR",
      "content": "The Dead Sea’s magnesium-rich minerals rejuvenate and soften skin and hair by enhancing hydration, strengthening the skin's barrier and promoting cellular health. Anti-inflammatory properties soothe skin irritations while detoxifying effects remove impurities. Mineral swim minerals can soothe and improve skin conditions such as eczema and psoriasis."
    },
    {
      "title": "GENTLE ON EYES & SENSITIVE SKIN",
      "content": "Magnesium salt softens and enhances water quality, making it much gentler than traditional salt and chlorine pools. This means no more red, irritated eyes and softer hair and skin. There is no strong smell of chlorine and no need to shower afterwards. It’s like spa therapy."
    },
    {
      "title": "IMPROVES WATER CLARITY",
      "content": "Dead Sea minerals improve water clarity by enhancing filtration efficiency and promoting natural flocculation, which clumps tiny particles together for easier removal. These minerals also maintain balanced water chemistry and reduce organic contaminants, resulting in consistently clear and sparkling pool water."
    }
  ],  
  "step2":[
    {
      "title": "MAYTRONICS FOR LIFE",
      "content": <>
      <p>Unlock exclusive access to the <a className='text-blue-600 hover:underline' rel="noopener noreferrer" href='https://www.maytronics.com.au/forlife/'>Maytronics For Life Benefits Program.</a></p>
      <p><span className='text-xl'>&#10003;</span> 30-Day Satisfaction Guarantee</p>
      <p><span className='text-xl'>&#10003;</span> Trade-in Guarantee</p>
      <p><span className='text-xl'>&#10003;</span> Annual Robot Health Check</p>
      <p><span className='text-xl'>&#10003;</span> Lifetime Professional Support</p></>
    },
    {
      "title": "CUSTOMER CARE PLAN",
      "content": "For complete peace of mind, your Mineral Swim™ system comes with a market-leading warranty, dedicated support, and complimentary pool system health checks six weeks and six months after installation. All you need to do is relax and enjoy the Mineral Swim™ experience. "
    },
    {
      "title": "DOLPHIN ROBOTIC POOL CLEANER",
      "content": "Enjoy effortless pool cleaning with a Dolphin robotic pool cleaner by Maytronics. Featuring industry-leading, cutting-edge technology for unrivalled pool cleaning performance and efficiency. Experience a sparkling clean pool with minimal effort. "
    },
    {
      "title": "pH BALANCER MODULE",
      "content": "Easy 'plug and play' module to allow automatic pH dosing - taking the hard work out of maintaining your swimming pool's pH water balance for comfortable all-year-round bathing."
    },
    {
      "title": "ENERGY-EFFICIENT ECOCLEAR POOL EQUIPMENT",
      "content": "Optimise your Mineral Swim™ system with premium, energy-efficient Ecoclear pool equipment by Maytronics. The range of high-quality pool pumps, heaters and filters ensures superior performance, cost savings and long-lasting pool installation. Upgrade for unparalleled pool maintenance and efficiency."
    }
  ],
  "step3":[
    {
      "title": "HEALTHY POOL SANITISER",
      "content": "Ozone instantly neutralises any organic contaminant in the water and reverts it to oxygen when it returns to your pool, leaving you with noticeably softer, safe, hygienic water."
    },
    {
      "title": "ENVIRONMENTALLY FRIENDLY",
      "content": "Mineral Swim purification system is proud to be Climate Care Certified, an environmental and sustainable efficiency and sustainability initiative of the Swimming Pool & Spa Association of Australia (SPASA). "
    },
    {
      "title": "REDUCES CHLORINE USAGE BY UP TO 80% ",
      "content": "Ozone generated from the Mineral Swim™ purifier is 3000 times more powerful than chlorine alone. It does most of the sanitising work in your pool, reducing the chlorine demand by up to 80% while leaving you with clear, clean, and odourless pool water. "
    },
    {
      "title": "SAFE FOR ALLERGIES, ASTHMA & ECZEMA SUFFERERS",
      "content": "Mineral Swim™ extends the life of your pool equipment by maintaining balanced water and reducing chlorine and chemical usage. This prevents corrosion and scale buildup. The high-water quality minimises residue and deposits on equipment, leading to less frequent maintenance and a longer lifespan for your pool equipment."
    },
    {
      "title": "SAVE TIME & MONEY ON POOL MAINTENANCE ",
      "content": "Natural ozone purification reduces the need for expensive and dangerous chemicals. A Mineral Swim™ system may be an initial, bigger investment than a standard salt chlorinator, but you will benefit from lower chemical and energy costs. "
    }
  ]
}

export default steps;